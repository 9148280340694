import React from "react";
import { Link } from "react-router-dom";


export default function NavBar() {
  return (
    <>
      <nav>
        <div className="Nav-logo">
          <Link to="/">
            {/* <svg width="23" height="12" viewBox="0 0 23 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.592 2.248C1.592 2.71734 1.77334 3.15467 2.136 3.56C2.50934 3.95467 2.95734 4.30667 3.48 4.616C4.01334 4.91467 4.54134 5.224 5.064 5.544C5.58667 5.85334 6.02934 6.23734 6.392 6.696C6.76534 7.15467 6.952 7.66134 6.952 8.216C6.952 9.09067 6.62667 9.80534 5.976 10.36C5.336 10.9147 4.46667 11.192 3.368 11.192C2.26934 11.192 1.18134 10.984 0.104004 10.568V9.816C1.192 10.36 2.11467 10.632 2.872 10.632C3.64 10.632 4.23734 10.4453 4.664 10.072C5.09067 9.69867 5.304 9.18134 5.304 8.52C5.304 8.072 5.128 7.66667 4.776 7.304C4.424 6.93067 3.99734 6.61067 3.496 6.344C2.99467 6.06667 2.49334 5.784 1.992 5.496C1.49067 5.208 1.064 4.84534 0.712004 4.408C0.360004 3.96 0.184004 3.42134 0.184004 2.792C0.184004 2.16267 0.461337 1.576 1.016 1.032C1.58134 0.488003 2.41867 0.216003 3.528 0.216003C4.25334 0.216003 5.17067 0.381337 6.28 0.712004L6.12 1.8C4.68 1.18134 3.448 0.872004 2.424 0.872004C1.86934 1.13867 1.592 1.59734 1.592 2.248Z"
                fill="#171717" />
              <path
                d="M10.7066 11H9.15463V1.176C8.79196 1.09067 8.4293 1.048 8.06663 1.048L5.90663 1.544L5.81063 0.408003H14.0506L13.9706 1.544L11.7946 1.048C11.432 1.048 11.0693 1.09067 10.7066 1.176V11Z"
                fill="#171717" />
              <path
                d="M22.4851 0.408003V6.904C22.4851 7.896 22.3785 8.64267 22.1651 9.144C22.0691 9.368 21.9145 9.624 21.7011 9.912C21.4985 10.1893 21.2585 10.4133 20.9811 10.584C20.2771 10.9893 19.3971 11.192 18.3411 11.192C15.6958 11.192 14.3731 9.832 14.3731 7.112V0.408003H16.0051V7.24C16.0051 7.54934 16.0105 7.8 16.0211 7.992C16.0318 8.184 16.0851 8.456 16.1811 8.808C16.2878 9.16 16.4265 9.44267 16.5971 9.656C16.7785 9.86934 17.0558 10.0613 17.4291 10.232C17.8131 10.4027 18.2665 10.488 18.7891 10.488C20.7838 10.488 21.7811 9.42134 21.7811 7.288V0.408003H22.4851Z"
                fill="#171717" />
            </svg> */}
            <h1 style={{margin:'2rem',fontSize:'4rem', fontWeight:'400'}}>
              STU FLEISHER
            </h1>
          </Link>
        </div>
        <div className="Nav-links">
          <Link to="/">About</Link>
          <Link to="/projects">Code</Link>
          <Link to="/videos">Videos</Link>
        </div>
      </nav>
    </>
  );
}